<template>
  <el-button id="cancel-btn" :disabled="isDisabled" @click="$emit('cancel')">Cancel
    <img src="../../assets/icon/red-arrow.svg" alt="">
  </el-button>
</template>

<script>
export default {
  props:{
    isDisabled: {
      type: Boolean,
      default:false
    }

  }
}
</script>

<style lang="scss" scoped>
#cancel-btn {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #f53d6b;
  
  padding: 16px 40px;
  width: 156px;
  height: 55px;
  border: 1.5px solid #f53d6b;
  border-radius: 4px;
  margin-right: 16px;

  img{
    margin-left:10px;
  }
}
</style>
